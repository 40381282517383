import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { Title, Container, Label, FlexContainer, FlexItem } from '@UI'
import { Fade } from '@animations'
import * as styles from './phaseTwo.module.scss'

const PhaseTwo = ({ title, body, imageData }) => {
  return (
    <Container bgAccent isSection>
      <Container wrapperSmOnMd section>
        <FlexContainer collapseOnMd>
          <FlexItem>
            <Fade className={styles.textContainer}>
              <Label>Phase Two</Label>
              <Container textBlock>
                <Title md>{title}</Title>
                <PrismicRichText field={body} />
              </Container>
            </Fade>
          </FlexItem>

          <FlexItem className={styles.flexItem}>
            <Fade x={30}>
              <GatsbyImage
                image={getImage(imageData)}
                alt={imageData.alt ? imageData.alt : ''}
              />
            </Fade>
          </FlexItem>
        </FlexContainer>
      </Container>
    </Container>
  )
}

export default PhaseTwo
