import React from 'react'
import { Container } from '@UI'
import ReactPlayer from 'react-player/lazy'
import * as styles from './fullWidthMockup.module.scss'

const FullWidthMockup = ({ videoUrl }) => {
  return (
    <Container wrapper sectionTop isSection className={styles.container}>
      <div className={styles.border}>
        <ReactPlayer
          className={styles.player}
          url={videoUrl}
          width={'100%'}
          height={'100%'}
          muted
          volume={0}
        />
      </div>
    </Container>
  )
}

export default FullWidthMockup
