import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import {
  Checklist as List,
  Container,
  FlexContainer,
  FlexItem,
  Gradient,
  Label,
  Title,
} from '@UI'
import { Fade } from '@animations'

import * as styles from './challenges.module.scss'

const Challenges = ({ title, body, list }) => {
  const data = list.map(({ objective }) => objective.text)

  return (
    <Container section isSection className={styles.container}>
      <Gradient />
      <Container wrapper>
        <Fade>
          <Title md center className={styles.title}>
            {title}
          </Title>
        </Fade>
        <FlexContainer alignStart collapseOnMd>
          <FlexItem>
            <Container wrapperSmOnMd noPadding textBlock>
              <Label>The Challenge</Label>
              <PrismicRichText field={body} />
            </Container>
          </FlexItem>

          <FlexItem>
            <Container wrapperSmOnMd noPadding>
              <List
                bullets
                data={data}
                itemClass={styles.item}
                listClass={styles.list}
              />
            </Container>
          </FlexItem>
        </FlexContainer>
      </Container>
    </Container>
  )
}

export default Challenges
