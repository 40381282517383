import React from 'react'
import classes from 'classnames'
import { Button, Container, Title, FlexContainer, FlexItem } from '@UI'
import { Fade, Zoom, Parallax } from '@animations'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import * as styles from './hero.module.scss'

const Hero = ({ image, bgImage, title, overview, services, siteUrl }) => {
  return (
    <section className={styles.container}>
      <Zoom className={classes(styles.imageContainer, styles.absolute)}>
        <GatsbyImage
          image={getImage(bgImage)}
          alt=''
          className={styles.absolute}
        />
      </Zoom>

      <Container wrapperSmOnMd className={styles.inner}>
        <FlexContainer collapseOnMd reverseOnMd>
          <FlexItem>
            <Fade>
              <Title tag='h1' className={styles.title}>
                {title}
              </Title>
            </Fade>

            <Fade delay={0.3}>
              <p>{overview}</p>
              {siteUrl && (
                <Button href={siteUrl} cta className={styles.button}>
                  View Live Site
                </Button>
              )}
            </Fade>

            <Fade delay={0.6} className={styles.servicesContainer}>
              <Title tag='h2' sm className={styles.servicesTitle}>
                Services:
              </Title>
              <ul className={styles.servicesList}>
                {services.map(({ list_item: { text } }) => (
                  <li className={styles.servicesListItem} key={text}>
                    {text}
                  </li>
                ))}
              </ul>
            </Fade>
          </FlexItem>

          <FlexItem>
            <Parallax>
              <Fade y={20}>
                <GatsbyImage image={getImage(image)} alt='' />
              </Fade>
            </Parallax>
          </FlexItem>
        </FlexContainer>
      </Container>
    </section>
  )
}

export default Hero
