import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { Container, Card } from '@UI'
import { Fade } from '@animations'
import { Star } from '@images/icons'
import * as styles from './testimonial.module.scss'

const Testimonial = ({ body, name, business }) => {
  return (
    <section className={styles.container}>
      <div className={styles.bg} />

      <Container wrapperSm className={styles.inner}>
        <Fade y={30}>
          <Card className={styles.card}>
            <div className={styles.cardStars}>
              {[...Array(5).keys()].map((_, i) => (
                <Star key={i} />
              ))}
            </div>
            <div>
              <PrismicRichText field={body} />
            </div>

            <div className={styles.info}>
              <p>{name}</p>
              <p>{business}</p>
            </div>
          </Card>
        </Fade>
      </Container>
    </section>
  )
}

export default Testimonial
