import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Layout, Seo, Cta } from '@global'
import {
  Challenges,
  Testimonial,
  PhaseOne,
  PhaseTwo,
  FullWidthMockup,
  Result,
  Gallery,
  Hero,
} from '@pages/caseStudy/page'

const CaseStudy = ({ data }) => {
  const study = data.prismicCaseStudies.data

  return (
    <Layout>
      <Seo
        title={`${study.main_title.text} | Stellr Web | Cody, WY`}
        description={study.excerpt.text}
        url={`https://www.stellrweb.com/case-studies/${data.prismicCaseStudies.uid}`}
        imageUrl={study.header_image.url}
      />

      <Helmet encodeSpecialCharacters={false}>
        <script type='application/ld+json'>
          {`
						{
							"@context": "https://schema.org/",
							"@type": "BreadcrumbList",
							"itemListElement": [{
								"@type": "ListItem",
								"position": 1,
								"name": "Case Studies",
								"item": "https://www.stellrweb.com/case-studies"
							},{
								"@type": "ListItem",
								"position": 2,
								"name": "${study.main_title.text}",
								"item": "https://www.stellrweb.com/case-studies/${data.prismicCaseStudies.uid}"
							}]
						}
        `}
        </script>
      </Helmet>

      <Hero
        image={study.header_image}
        bgImage={study.main_bg_image}
        title={study.main_title.text}
        overview={study.overview.text}
        services={study.services_list}
        siteUrl={study.live_site_link.text}
      />

      <Challenges
        title={study.challenge_title.text}
        body={study.challenge_body.richText}
        list={study.project_objectives}
      />

      <PhaseOne
        title={study.phase_one_title.text}
        body={study.phase_one_body.richText}
        imageData={study.phase_one_img}
      />

      {!!study.testimonial_body.richText.length && (
        <Testimonial
          body={study.testimonial_body.richText}
          name={study.testimonial_name.text}
          business={study.testimonial_business.text}
        />
      )}

      <PhaseTwo
        title={study.phase_two_title.text}
        body={study.phase_two_body.richText}
        imageData={study.phase_two_img}
      />

      <FullWidthMockup videoUrl={study.video_url.text} />

      <Result body={study.result_body.richText} list={study.result_list} />

      <Gallery images={study.gallery} />

      <Cta />
    </Layout>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query StudiesBySlug($uid: String!) {
    prismicCaseStudies(uid: { eq: $uid }) {
      uid
      data {
        main_title {
          text
        }
        excerpt {
          text
        }
        overview {
          text
        }
        services_list {
          list_item {
            text
          }
        }
        challenge_title {
          text
        }
        challenge_body {
          richText
        }
        project_objectives {
          objective {
            text
          }
        }
        testimonial_body {
          richText
        }
        testimonial_name {
          text
        }
        testimonial_business {
          text
        }
        phase_one_title {
          text
        }
        phase_one_body {
          richText
        }
        phase_one_img {
          alt
          gatsbyImageData(layout: CONSTRAINED, width: 700, placeholder: BLURRED)
        }
        phase_two_title {
          text
        }
        phase_two_body {
          richText
        }
        phase_two_img {
          alt
          gatsbyImageData(layout: CONSTRAINED, width: 700, placeholder: BLURRED)
        }
        video_url {
          text
        }
        result_body {
          richText
        }
        result_list {
          result_list_item {
            text
          }
        }
        live_site_link {
          text
        }
        header_image {
          url
          gatsbyImageData(layout: CONSTRAINED, width: 1400, placeholder: NONE)
        }
        main_bg_image {
          url
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        gallery {
          gallery_image {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 700
              placeholder: BLURRED
            )
            url
          }
        }
      }
    }
  }
`
