import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { Title, Container, Label, FlexContainer, FlexItem } from '@UI'
import { Fade } from '@animations'

import * as styles from './phaseOne.module.scss'

const PhaseOne = ({ title, body, imageData }) => {
  return (
    <Container wrapperSmOnMd section isSection>
      <FlexContainer collapseOnMd>
        <FlexItem className={styles.flexItem}>
          <Fade x={-30}>
            <GatsbyImage
              image={getImage(imageData)}
              alt={imageData.alt ? imageData.alt : ''}
            />
          </Fade>
        </FlexItem>
        <FlexItem>
          <Fade className={styles.textContainer}>
            <Label>Phase One</Label>
            <Container textBlock>
              <Title md>{title}</Title>
              <PrismicRichText field={body} />
            </Container>
          </Fade>
        </FlexItem>
      </FlexContainer>
    </Container>
  )
}

export default PhaseOne
