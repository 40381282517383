import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container, Title } from '@UI'
import { Fade } from '@animations'
import * as styles from './gallery.module.scss'

const Gallery = ({ images }) => {
  return (
    <Container wrapper sectionBottom isSection>
      <Fade>
        <Title center>Screenshot Gallery</Title>
      </Fade>

      <div className={styles.gridContainer}>
        {images.map(({ gallery_image }) => (
          <a
            href={gallery_image.url}
            key={gallery_image.url}
            target='_blank'
            rel='noreferrer'
            className={styles.link}
          >
            <GatsbyImage
              image={getImage(gallery_image)}
              layout='constrained'
              width={700}
              placeholder='blurred'
              alt=''
            />
          </a>
        ))}
      </div>
    </Container>
  )
}

export default Gallery
