import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import {
  Checklist as List,
  Container,
  Label,
  FlexContainer,
  FlexItem,
  Title,
} from '@UI'

import * as styles from './result.module.scss'

const Result = ({ body, list }) => {
  const data = list.map(({ result_list_item }) => result_list_item.text)

  return (
    <Container wrapperSmOnMd section>
      <Label>Final Result</Label>
      <Title className={styles.title}>Bringing It Together</Title>
      <FlexContainer collapseOnMd alignStart>
        <FlexItem>
          <Container textBlock>
            <PrismicRichText field={body} />
          </Container>
        </FlexItem>
        <FlexItem>
          <List data={data} listClass={styles.list} />
        </FlexItem>
      </FlexContainer>
    </Container>
  )
}

export default Result
